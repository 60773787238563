//
// Header menu light theme
//

// Initialization of global variables, mixins and functions
@import "../../../../init";

// Build Desktop Header Menu Theme
@include menu-hor-build-theme(get($header-menu-config, desktop), light);

// Build Mobile Header Menu Theme
@include menu-ver-build-theme(get($header-menu-config, tablet-and-mobile), light);
